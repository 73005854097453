<template>
  <div v-loading="isLoading">
    <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <easy-card title="运营规则">
        <el-row>
          <el-col :span="12">
            <el-form-item label="泊位监控" prop="berthMonitoring" required>
              <el-select v-model="model.berthMonitoring" style="width: 100%">
                <el-option label="可监控" value="1"></el-option>
                <el-option label="不可监控" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="线上停车订单"
                prop="onlineParkingOrder"
                required
            >
              <el-select v-model="model.onlineParkingOrder" style="width: 100%">
                <el-option label="运营" value="1"></el-option>
                <el-option label="全数据" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
                label="订单预支付"
                prop="orderAdvancePayment"
                required
            >
              <el-select
                  v-model="model.orderAdvancePayment"
                  style="width: 100%"
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="收费资质"
                prop="chargingQualification"
                required
            >
              <el-select
                  v-model="model.chargingQualification"
                  style="width: 100%"
              >
                <el-option label="平台收费" value="1"></el-option>
                <el-option label="独立收费" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
                label="ETC结算平台"
                prop="etcSettlementPlatform"
                required
            >
              <el-select
                  v-model="model.etcSettlementPlatform"
                  style="width: 100%"
              >
                <el-option label="ETC" value="1"></el-option>
                <el-option label="ETC-SD" value="2"></el-option>
                <el-option label="ETC-QT" value="3"></el-option>
                <el-option label="ETC-ZG" value="4"></el-option>
                <el-option label="无" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </easy-card>
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </el-form>
  </div>
</template>

<script>
import {createAndUpdateOperatingRules} from "@/api/parking";

export default {
  name: "parking-operation",
  props: {
    parkingId: String,
    parkingModelJSON: String,
  },
  data() {
    return {
      isLoading: false,
      model: {
        parkingId: "",
        berthMonitoring: "",
        onlineParkingOrder: "",
        orderAdvancePayment: "",
        chargingQualification: "",
        etcSettlementPlatform: "",
      },
      rules: {
        berthMonitoring: [{required: true, message: "请选择泊位监控"}],
        onlineParkingOrder: [{required: true, message: "请选择线上停车订单"}],
        orderAdvancePayment: [{required: true, message: "请选择订单预支付"}],
        chargingQualification: [{required: true, message: "请选择收费资质"}],
        etcSettlementPlatform: [
          {required: true, message: "请选择ETC结算平台"},
        ],
      },
    };
  },
  watch: {
    parkingModelJSON(newVal) {
      if (newVal !== "") {
        const returnObject = JSON.parse(newVal);
        this.model.parkingId = this.parkingId;
        // this.model.berthMonitoring = !isNaN(returnObject.isMonitoringParking)
        //   ? returnObject.isMonitoringParking.toString()
        //   : returnObject.isMonitoringParking;
        this.model.berthMonitoring =
            returnObject.isMonitoringParking === 0
                ? ""
                : returnObject.isMonitoringParking.toString();
        this.model.onlineParkingOrder = returnObject.onlineParkingOrder;
        this.model.orderAdvancePayment = returnObject.orderAdvancePayment;
        this.model.chargingQualification = returnObject.chargingQualification;
        this.model.etcSettlementPlatform = returnObject.etcSettlementPlatform;
      }
    },
  },
  // mounted() {
  //   const parkingId = this.$route.query.parkingId;

  //   if (parkingId) {
  //     this.getParkingDetail(parkingId);
  //   }
  // },
  methods: {
    // async getParkingDetail(parkingId) {
    //   this.isLoading = true;
    //   const res = await getParkingDetail(parkingId);
    //   this.isLoading = false;

    //   if (res && res.code === 30 && res.result) {
    //     const returnObject = res.returnObject;
    //     this.model.parkingId = parkingId;
    //     this.model.berthMonitoring = !isNaN(returnObject.isMonitoringParking)
    //       ? returnObject.isMonitoringParking.toString()
    //       : returnObject.isMonitoringParking;
    //     this.model.onlineParkingOrder = returnObject.onlineParkingOrder;
    //     this.model.orderAdvancePayment = returnObject.orderAdvancePayment;
    //     this.model.chargingQualification = returnObject.chargingQualification;
    //     this.model.etcSettlementPlatform = returnObject.etcSettlementPlatform;
    //   }
    // },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.model.parkingId = this.parkingId;
        this.isLoading = true;
        const res = await createAndUpdateOperatingRules(this.model);
        this.isLoading = false;

        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "保存车场运营规则成功!",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
