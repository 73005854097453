<template>
  <div v-loading="isLoading" class="share-rules">
    <easy-card title="运营信息">
      <el-form ref="model" :model="model" label-width="120px" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="结算时间" prop="settlementDateType" required>
              <el-select
                  v-model="model.settlementDateType"
                  placeholder="请选择结算时间"
              >
                <el-option label="及时" value="IN_TIME"></el-option>
                <el-option label="日" value="DAY"></el-option>
                <el-option label="周" value="WEEK"></el-option>
                <el-option label="月" value="MONTH"></el-option>
                <el-option label="季度" value="SEASON"></el-option>
                <el-option label="年" value="YEAR"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="合同价款">
              <el-input
                v-model="model.parkingName"
                placeholder="车场名称"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="月回款额">
              <el-input v-model="model.parkingName" placeholder="车场名称">
                <el-button>元</el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="回款日期">
              <el-input
                v-model="model.parkingName"
                placeholder="车场名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
    </easy-card>
    <easy-card title="停车业务分润 *">
      <icon-button
          slot="extra"
          icon="add-select"
          @click.native="addParkingShare"
      />
      <el-table
          :data="parkingShareList"
          class="common-table"
          style="width: 100%"
      >
        <el-table-column label="结算方名称" prop="settlementName" width="350">
        </el-table-column>
        <!-- <el-table-column label="账期（天）" width="180"> </el-table-column> -->
        <el-table-column label="分润比例(%)" prop="amount" width="200">
        </el-table-column>
        <el-table-column label="说明" prop="description"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-link type="primary" @click="deleteParkingShare(scope.row)"
            >删除
            </el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </easy-card>
    <easy-card title="长租业务分润">
      <icon-button
          slot="extra"
          icon="add-select"
          @click.native="addLeaseShare"
      />
      <el-table :data="leaseShareList" class="common-table" style="width: 100%">
        <el-table-column label="结算方名称" prop="settlementName" width="350">
        </el-table-column>
        <!-- <el-table-column label="账期（天）" width="180"> </el-table-column> -->
        <el-table-column label="分润比例(%)" prop="amount" width="200">
        </el-table-column>
        <el-table-column label="说明" prop="description"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-link type="primary" @click="deleteLeaseShare(scope.row)"
            >删除
            </el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </easy-card>
    <el-button class="xxd-button_save" type="primary" @click="saveShareRules"
    >保存
    </el-button
    >

    <!-- 敬请期待 -->

    <el-dialog
        :append-to-body="true"
        :before-close="beforeAddShareDialogClosed"
        :visible.sync="addShareDialogFormVisible"
        class="common-dialog"
        title="添加结算方分润比率"
        width="600px"
    >
      <el-form
          ref="form"
          v-loading="isAddLoading"
          :model="form"
          :rules="rules"
          class="label-weight"
      >
        <el-form-item
            label="选择结算方"
            label-width="120px"
            prop="userId"
            required
        >
          <Settlement
              ref="addSettlement"
              v-model="form.userId"
              style="width: 400px"
          />
        </el-form-item>

        <el-form-item
            label="分润比例(%)"
            label-width="120px"
            prop="amount"
            required
        >
          <el-input
              v-model="form.amount"
              placeholder="请输入分润比例"
              style="width: 400px"
          ></el-input>
        </el-form-item>

        <el-form-item label="说明" label-width="120px" prop="description">
          <el-input
              v-model="form.description"
              :rows="2"
              placeholder="请输入说明内容"
              style="width: 400px"
              type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">确定</el-button>
        <el-button @click="closed">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Settlement from "@/components/Settlement";
import {createSettlementConfig, getSettlementConfigByObjectId,} from "@/api/parking";

export default {
  name: "share-rules",
  props: {
    parkingId: String,
    parkingName: String,
  },
  components: {
    Settlement,
  },
  data() {
    return {
      id: "",//分润表主键id
      model: {
        settlementDateType: "",
      },
      addShareDialogFormVisible: false,
      form: {
        userId: "",
        amount: "",
        description: "",
        id: "",
        settlementInfoId: "",
        tenantId: "", //业务类型（PARKING_LOT_LEASE_TENANT_ID长租 和 PARKING_TENANT_ID停车）
        type: "RATIO", //类型 （RATIO 比例 或 MONEY 金额） 先写死RATIO
        settlementName: "",
      },
      rules: {
        userId: [{required: true, message: "请选择结算方"}],
        amount: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("分润比例不能为空"));
              } else if (
                  !/^\d\.([1-9]{1,2}|[0-9][1-9])$|^[1-9]\d{0,1}(\.\d{1,2}){0,1}$|^100(\.0{1,2}){0,1}$/.test(
                      value
                  )
              ) {
                callback(
                    new Error("请输入(大于0小于等于100的数字,小数点后最多两位)")
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      isAddLoading: false,
      isLoading: false,
      parkingShareList: [], //停车分润数据
      leaseShareList: [], //长租分润数据
    };
  },
  methods: {
    //添加停车业务分润
    addParkingShare() {
      this.addShareDialogFormVisible = true;
      this.form.tenantId = "PARKING_TENANT_ID";
    },
    //添加长租业务分润
    addLeaseShare() {
      this.addShareDialogFormVisible = true;
      this.form.tenantId = "PARKING_LOT_LEASE_TENANT_ID";
    },
    //关闭添加弹窗
    closed() {
      this.addShareDialogFormVisible = false;
      this.$refs.form.resetFields();
    },
    //关闭弹窗清理表单内容
    beforeAddShareDialogClosed(done) {
      this.$refs.form.resetFields();
      done();
    },
    //保存分润数据表
    save() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        const data = JSON.parse(JSON.stringify(this.form));
        const settlementData = this.$refs.addSettlement.getCurrentItem();
        data.settlementName = settlementData.name;
        if (data.tenantId === "PARKING_TENANT_ID") {
          this.parkingShareList.push(data);
        } else if (data.tenantId === "PARKING_LOT_LEASE_TENANT_ID") {
          this.leaseShareList.push(data);
        }
        this.addShareDialogFormVisible = false;
        this.$refs.form.resetFields();
      });
    },
    //删除停车业务分润表
    deleteParkingShare(e) {
      const dataList = this.parkingShareList;
      dataList.splice(dataList.indexOf(e), 1);
    },
    //删除长租业务分润表
    deleteLeaseShare(e) {
      const dataList = this.leaseShareList;
      dataList.splice(dataList.indexOf(e), 1);
    },

    //保存分润规则
    saveShareRules() {
      const id = this.id;
      const name = this.parkingName;
      const objectId = this.parkingId;
      const settlementDateType = this.model.settlementDateType;
      const parking = this.parkingShareList;
      const parkingLotLease = this.leaseShareList;
      if (settlementDateType === "") {
        this.$message.error("请选择结算时间");
        return false;
      } else if (parking.length === 0) {
        this.$message.error("请添加停车业务分润");
        return false;
      }
      const parkingAmount = parking.map(item => {
        return item.amount;
      })
      const parkingSum = eval(parkingAmount.join("+"));
      if (parkingSum !== 100) {
        this.$message({
          message: '停车业务分润比例不是百分之百',
          type: 'warning'
        });
        return false;
      }
      const leaseAmount = parkingLotLease.map(item => {
        return item.amount;
      })
      const leaseSum = eval(leaseAmount.join("+"));
      if (leaseSum !== 100) {
        this.$message({
          message: '长租业务分润比例不是百分之百',
          type: 'warning'
        });
        return false;
      }
      const params = {
        id,
        name,
        objectId,
        parking,
        parkingLotLease,
        regionId: "530129",
        settlementDateType,
      };
      this.isLoading = true;
      createSettlementConfig(params).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "保存车场分润规则成功!",
            type: "success",
          });
        }
      });
    },

    //编辑查询已保存的信息
    getShareDetail(parkingId) {
      getSettlementConfigByObjectId(parkingId).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.id = res.returnObject.id;
          this.model.settlementDateType = res.returnObject.settlementDateType;
          this.parkingShareList = res.returnObject.parking;
          this.leaseShareList = res.returnObject.parkingLotLease;
        }
      });
    },
  },
  mounted() {
    const parkingId = this.$route.query.parkingId;
    // const parkingName = this.$route.query.name;
    if (parkingId) {
      // this.parkingId = parkingId;
      // this.parkingName = parkingName;
      this.getShareDetail(parkingId);
    }
  },
};
</script>

<style lang="less" scoped>
// .share-rules {
//   height: 300px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   font-size: 20px;
//   color: #999;
// }
</style>
