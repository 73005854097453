<template>
  <div v-loading="isLoading">
    <el-form ref="form" :model="model" label-width="160px" size="small">
      <easy-card title="产权信息">
        <el-row>
          <el-col :span="12">
            <el-form-item label="产权单位">
              <el-input
                  v-model="model.propertyRightUnit"
                  placeholder="产权单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产权方联系人">
              <el-input
                  v-model="model.propertyOwner"
                  placeholder="产权方联系人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产权方电话">
              <el-input v-model="model.propertyTel" placeholder="产权方电话">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车场属性">
              <el-select
                  v-model="model.parkingLotProperties"
                  placeholder="车场属性"
                  style="width: 100%"
              >
                <!-- <el-option value="" label="请选择"></el-option> -->
                <el-option label="停车库" value="1"></el-option>
                <el-option label="停车场" value="2"></el-option>
                <el-option label="停车楼" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="建筑属性">
              <el-select
                  v-model="model.architecturalProperties"
                  placeholder="建筑属性"
                  style="width: 100%"
              >
                <el-option label="其他" value="0"></el-option>
                <el-option label="新建" value="1"></el-option>
                <el-option label="改建" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="建设时间">
              <el-date-picker
                  v-model="model.constructionTime"
                  placeholder="建设时间"
                  style="width: 100%"
                  type="date"
                  value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="时间年限(年)">
              <el-input v-model="model.serviceLife" placeholder="时间年限(年)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车场描述">
              <el-input
                  v-model="model.parkingDesc"
                  placeholder="车场描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="入口数量">
              <el-input v-model="model.entryNumber" placeholder="入口数量">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出口数量">
              <el-input
                  v-model="model.exportNumber"
                  placeholder="出口数量"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </easy-card>
      <easy-card title="许可信息">
        <icon-button slot="extra" icon="add-select" @click.native="showAdd"/>
        <el-table
            :data="model.licenseInformationList"
            class="common-table"
            style="width: 100%"
        >
          <el-table-column label="许可证编号" prop="licenseNumber">
          </el-table-column>
          <el-table-column label="有效期" prop="createDate" width="280">
            <template slot-scope="scope">
              <span>{{ scope.row.validityBeginTime }}</span
              >至<span>{{ scope.row.validityEndTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="经营范围" prop="natureBusiness">
          </el-table-column>
          <el-table-column label="图片" prop="image">
            <template slot-scope="scope">
              <div style="width: 160px; height: 180px">
                <img
                    :src="scope.row.imageUrl"
                    style="width: 100%; height: 100%"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button
                  size="small common-text"
                  type="text"
                  @click="remove(scope.$index)"
              >删除
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </easy-card>
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </el-form>
    <el-dialog
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        class="common-dialog"
        title="添加许可信息"
        width="30%"
    >
      <el-form ref="addForm" :model="add" :rules="rules" label-width="90px">
        <el-form-item label="图片" prop="picture" required>
          <el-upload
              :http-request="doUpload"
              :show-file-list="false"
              action=""
              class="avatar-uploader"
          >
            <img v-if="add.imageUrl" :src="add.imageUrl" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="许可证号" prop="licenseNumber" required>
          <el-input v-model="add.licenseNumber" placeholder="许可证号"/>
        </el-form-item>
        <el-form-item label="有效期" prop="createDate" required>
          <el-date-picker
              v-model="add.createDate"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              style="width: 100%"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="经营范围" prop="natureBusiness" required>
          <el-input
              v-model="add.natureBusiness"
              placeholder="经营范围"
              type="textarea"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {doUploadImage, getParkingImage, getParkingLicenseByPage, saveExtension,} from "@/api/parking";

import {dateFormat} from "@/utils/utils";

export default {
  name: "parking-extension",
  props: {
    parkingId: String,
    parkingModelJSON: String,
  },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      rules: {
        picture: [{required: true, message: "请选择图片"}],
        licenseNumber: [{required: true, message: "许可证号不能为空"}],
        createDate: [{required: true, message: "有效期不能为空"}],
        natureBusiness: [{required: true, message: "经营范围不能为空"}],
      },
      add: {
        imageUrl: "",
        picture: "",
        licenseNumber: "",
        createDate: [],
        natureBusiness: "",
        validityBeginTime: "",
        validityEndTime: "",
      },
      model: {
        parkingId: "",
        propertyRightUnit: "",
        propertyOwner: "",
        propertyTel: "",
        parkingLotProperties: "",
        architecturalProperties: "",
        constructionTime: "",
        serviceLife: "",
        parkingDesc: "",
        entryNumber: "",
        exportNumber: "",
        licenseInformationList: [],
      },
    };
  },
  watch: {
    "add.picture"(newVal) {
      if (newVal) {
        this.getParkingImage(newVal);
      }
    },
    parkingModelJSON(newVal) {
      if (newVal !== "") {
        const returnObject = JSON.parse(newVal);

        this.model = {
          parkingId: returnObject.id,
          propertyRightUnit: returnObject.propertyUnit,
          propertyOwner: returnObject.propertyUnitLinkMan,
          propertyTel: returnObject.propertyUnitLinkTel,
          parkingLotProperties:
              returnObject.parkingLotAttribute === 0
                  ? ""
                  : returnObject.parkingLotAttribute.toString(),
          architecturalProperties: returnObject.buildingAttribute.toString(),
          constructionTime: returnObject.buildTime,
          serviceLife: returnObject.serviceLife,
          parkingDesc: returnObject.description,
          entryNumber: returnObject.entryNumber,
          exportNumber: returnObject.exportNumber,
          licenseInformationList: [],
        };

        this.getParkingLicenseList(returnObject.id);
      }
    },
  },
  methods: {
    remove(index) {
      this.model.licenseInformationList.splice(index, 1);
    },
    doAdd() {
      this.$refs.addForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.add.validityBeginTime = this.add.createDate[0];
        this.add.validityEndTime = this.add.createDate[1];

        this.model.licenseInformationList.push(
            JSON.parse(JSON.stringify(this.add))
        );

        this.$refs.addForm.resetFields();
        this.add.imageUrl = "";
        this.dialogVisible = false;
      });
    },
    async getParkingImage(attachmentId) {
      const res = await getParkingImage(attachmentId);
      if (res && res.code === 30 && res.result) {
        this.add.imageUrl = res.returnObject;
      }
    },
    showAdd() {
      this.dialogVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    async doUpload(file) {
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.add.picture = res.returnObject.id;
      }
    },
    async save() {
      this.model.parkingId = this.parkingId;

      this.isLoading = true;
      const res = await saveExtension(this.model);
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        this.$message({
          message: "保存车场扩展信息成功!",
          type: "success",
        });
        this.$emit("success");
      }
    },
    async getParkingLicenseList(parkingId) {
      const res = await getParkingLicenseByPage({
        parkingId: parkingId,
        pageNo: 1,
        pageSize: 1000,
      });
      if (res && res.code === 30 && res.result) {
        this.model.licenseInformationList = res.returnObject.list.map(
            (item) => {
              const effectiveStart = new Date(item.effectiveStart);
              const effectiveEnd = new Date(item.effectiveEnd);

              return {
                imageUrl: "",
                picture: item.licenseImage,
                licenseNumber: item.licenceNumber,
                createDate: [effectiveStart, effectiveEnd],
                natureBusiness: item.businessScope,
                validityBeginTime: dateFormat("yyyy-MM-dd", effectiveStart),
                validityEndTime: dateFormat("yyyy-MM-dd", effectiveEnd),
              };
            }
        );

        this.model.licenseInformationList.forEach((item) => {
          getParkingImage(item.picture).then((res) => {
            if (res && res.code === 30 && res.result) {
              item.imageUrl = res.returnObject;
            }
          });
        });
      }
    },
  },
  mounted() {
    // const parkingId = this.$route.query.parkingId;
    // if (parkingId) {
    //   this.getParkingLicenseList();
    // }
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #0768FD;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
